import {useNavigate} from "react-router-dom";

// 订阅路由
export const useSubscribeRouter = () => {
    const navigate = useNavigate();
    const subscribeRouter = (params?: string) => {
        if (params) {
            navigate(params)
        } else {
            navigate('/pricing')
        }

    }
    return {subscribeRouter}
}
