import {useGetIdentity} from "@refinedev/core";
import {MeRoot} from "../lib/api/me/type";

export const useSubscribe = () => {
    const {data: identity} = useGetIdentity<MeRoot>();

    // 是否订阅
    const isSubscribe =
        identity?.account_type === "PRO" || identity?.account_type === "TRANSLATE";
    return {isSubscribe};
};
