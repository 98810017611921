import axios, {InternalAxiosRequestConfig} from "axios";
import {deleteAuthToken, getAuthToken} from "./common";

// prefix
const BASE_URL_COMPANY_PREFIX = import.meta.env
    .VITE_APP_BASE_URL_COMPANY_PREFIX;
// BASE_URL
export const BASE_URL = import.meta.env.VITE_APP_BASE_URL;
// BASE_URL_COMPANY
export const BASE_URL_COMPANY =
    import.meta.env.VITE_APP_BASE_URL_COMPANY + BASE_URL_COMPANY_PREFIX;
// BASE_URL_COMMENT
export const BASE_URL_COMMENT = import.meta.env.VITE_APP_BASE_URL_COMMENT;


// axios实例
export const request = axios.create({
    baseURL: BASE_URL,
});
// axios实例
export const companyRequest = axios.create({
    baseURL: BASE_URL_COMPANY,
});

// GET缓存一周
companyRequest.defaults.headers.get['Cache-Control'] = 'public,max-age=604800'

// axios实例
export const commentRequest = axios.create({
    baseURL: BASE_URL_COMMENT,
});

// 请求拦截器
commentRequest.interceptors.request.use(
    (request: InternalAxiosRequestConfig) => {
        // 获取token
        const token = getAuthToken();
        // 携带token
        if (token) {
            request.headers.Authorization = `Bearer ${token}`;
        }
        return request;
    }
);
// 响应拦截器
commentRequest.interceptors.response.use(
    function (response) {
        // 2xx 范围内的状态码都会触发该函数。

        return response;
    },
    async function (error) {
        await rejected(error);
    }
);

// 请求拦截器
companyRequest.interceptors.request.use(
    (request: InternalAxiosRequestConfig) => {
        // 获取token
        const token = getAuthToken();
        // 携带token
        if (token) {
            request.headers.Authorization = `Bearer ${token}`;
        }
        return request;
    }
);
// 响应拦截器
companyRequest.interceptors.response.use(
    function (response) {
        // 2xx 范围内的状态码都会触发该函数。

        return response;
    },
    async function (error) {
        await rejected(error);
    }
);
// 请求拦截器
request.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    // 获取token
    const token = getAuthToken();
    // 携带token
    if (token) {
        request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
});
// 响应拦截器
request.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        await rejected(error);
    }
);
// 错误处理
const rejected = (error: any) => {
    const {
        response: {status},
    } = error;
    if (status === 401) {
        deleteAuthToken();
        return Promise.reject(error);
    }

    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
};
