import dayjs, {Dayjs} from "dayjs";
import numeral from "numeral";
import relativeTime from "dayjs/plugin/relativeTime";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import {Location} from "react-router-dom";
import "dayjs/locale/zh-cn";
import "dayjs/locale/es";
import "dayjs/locale/en";
import calendar from "dayjs/plugin/calendar";
import duration from "dayjs/plugin/duration";
import {GeneralSearchData, RootDocumentListData} from "../types";
import {Conversation} from "../contexts/chat-context/type";
import {DATE_FORMAT} from "../constant/comon";

dayjs.extend(quarterOfYear);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(calendar);
/**
 * 时间戳格式化
 * @param timestamp
 */
export const timeFromNow = (timestamp: string) => {
    return dayjs(+timestamp).fromNow();
};

/**
 * 周格式化
 * @param date
 * @param locale
 */
export const dayWithWeekAndMonthFormat = (date: string, locale: string) => {
    if (!date || !locale) return null;
    const _locale = locale === "zh" ? "zh-cn" : locale;
    return {
        dddd: dayjs(date).locale(_locale).format("dddd"),
        MMMDD:
            locale === "zh"
                ? dayjs(date).locale(_locale).format("MMMDD日")
                : dayjs(date).locale(_locale).format("MMM DD"),
    };
};

/**
 * 时间格式化
 * @param date
 */
export const timeFormat = (date: string) => {
    if (!date) {
        return dayjs().format("YYYY/MM/DD");
    }
    return dayjs(date).format("YYYY/MM/DD");
};
// token
export const AUTH_TOKEN: string = "docAuthToken";
// 搜索历史记录
export const SEARCH_HISTORY: string = "searchHistory";
// OPEN_REPLAY_NANNO_ID
export const OPEN_REPLAY_NANNO_ID: string = "openReplayNannoId";
// 订阅成功后重定向url
export const PLAN_SUCCESS_REDIRECT_URL: string = "planSuccessRedirectUrl";
// 深度翻译
export const EARNING_CALL_INDEX: string = "earningCallIndex";
/**
 * 保存token
 * @param token
 */
export const saveAuthToken = (token: string) => {
    if (!token) return token;
    window.localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
};

/**
 * 获取token
 */
export const getAuthToken = (): string => {
    const token = window.localStorage.getItem(AUTH_TOKEN);
    return token && JSON.parse(token);
};
/**
 * 移除token
 */
export const deleteAuthToken = () => {
    window.localStorage.removeItem(AUTH_TOKEN);
};

/**
 * 清空localStorage
 */
export const clearLocalStorage = () => {
    window.localStorage.clear();
};

/**
 * Conversations 存储对话
 * @param conversations
 */
export const saveConversations = (conversations: Conversation[]) => {
    localStorage.setItem("conversationHistory", JSON.stringify(conversations));
};

/**
 * 保存搜索记录
 * @param history
 */
export const saveSearchHistory = (history: GeneralSearchData[]) => {
    localStorage.setItem(SEARCH_HISTORY, JSON.stringify(history));
};

/**
 * 获取搜索记录
 */
export const getSearchHistory = () => {
    return localStorage.getItem(SEARCH_HISTORY);
};

/**
 * 保存重定向location
 * @param location
 */
export const savePlanRedirectUrl = (location: Location) => {
    localStorage.setItem(PLAN_SUCCESS_REDIRECT_URL, JSON.stringify(location));
};

/**
 * 获取已保存的重定向location
 */
export const getPlanRedirectUrl = () => {
    return localStorage.getItem(PLAN_SUCCESS_REDIRECT_URL);
};

/**
 * 保存选中的EarningCall
 * @param earningCall
 */
export const saveEarningCallIndex = (earningCall: RootDocumentListData) => {
    localStorage.setItem(EARNING_CALL_INDEX, JSON.stringify(earningCall));
};

/**
 * 获取已保存的EarningCall
 */
export const getEarningCallIndex = () => {
    return localStorage.getItem(EARNING_CALL_INDEX);
};

/**
 * 删除已保存的EarningCall
 */
export const deleteEarningCallIndex = () => {
    localStorage.removeItem(EARNING_CALL_INDEX);
};
// 获取半年起始时间
export const queryMonthPeriod = (period: number = 6) => {
    const date_from = dayjs().subtract(period, "month").format("YYYY-MM-DD");
    const date_to = dayjs().format("YYYY-MM-DD");
    return {
        date_from,
        date_to,
    };
};

// 获取已保存的OpenReplayId
export const getOpenReplayNannoId = () => {
    return localStorage.getItem(OPEN_REPLAY_NANNO_ID);
};

// 保存OpenReplayId
export const saveOpenReplayNannoId = (replayNannoId: string) => {
    if (!replayNannoId) return replayNannoId;
    window.localStorage.setItem(OPEN_REPLAY_NANNO_ID, replayNannoId);
};

// 金额格式化
export const numeralFormat = (n: number): string => {
    return numeral(n).format("0,0");
};

// 生成日历环 数组length只能为奇数
export function generateDateRange(dateStr: Dayjs, length: number) {
    const dataOfMonth = "MMM DD";
    const dayOfWeek = "ddd";
    const _length = (length - 1) / 2;
    // 将输入的日期字符串转换为日期对象
    const inputDate = dayjs(dateStr);

    // 创建一个新的数组来存储结果
    const dateRange = [];

    // 计算前两天的日期
    for (let i = _length; i > 0; i--) {
        const newDate = inputDate.subtract(i, "day");
        dateRange.push({
            date: newDate.format(DATE_FORMAT),
            dataOfMonth: newDate.format(dataOfMonth),
            dayOfWeek: newDate.format(dayOfWeek),
        });
    }

    // 将输入的日期添加到数组中
    dateRange.push({
        date: inputDate.format(DATE_FORMAT),
        dataOfMonth: inputDate.format(dataOfMonth),
        dayOfWeek: inputDate.format(dayOfWeek),
    });

    // 计算后两天的日期
    for (let i = 1; i <= _length; i++) {
        const newDate = inputDate.add(i, "day");
        dateRange.push({
            date: newDate.format(DATE_FORMAT),
            dataOfMonth: newDate.format(dataOfMonth),
            dayOfWeek: newDate.format(dayOfWeek),
        });
    }

    return dateRange;
}

// noop
export const noop = () => {
};

// news格式化时间
// 1个小时内的显示：xxx minutes ago
// 6个小时到1个小时内的显示：xxx hours ago
// 当前年份显示:月-日 00-00 00:00
// 上一年份显示:年-月-日 0000-00-00 00:00
export const newsDateFormat = (date: string | Dayjs = dayjs()) => {
    const currentYear = dayjs().year();
    const dateToCheck = dayjs(date);
    // 与当前时间差
    const dateToDiff = dayjs().diff(dateToCheck, "hour");
    // 检查年份是否小于当前年份
    const isBeforeCurrentYear = dateToCheck.year() < currentYear;
    if (isBeforeCurrentYear) {
        // 上一年份显示格式
        return dateToCheck.format("YYYY-MM-DD HH:mm");
    }
    if (dateToDiff <= 6) {
        // 小于6小时显示格式
        return dayjs(date).fromNow();
    }
    // 当前年份显示格式
    return dateToCheck.format("MM-DD HH:mm");
};

/**
 * dateFormat
 * @param date
 */
export const dateFormat = (date: string | Dayjs = dayjs()) => {
    const currentYear = dayjs().year();
    const dateToCheck = dayjs(date);
    // 检查年份是否小于当前年份
    const isBeforeCurrentYear = dateToCheck.year() < currentYear;
    if (isBeforeCurrentYear) {
        // 上一年份显示格式
        return dateToCheck.format("YYYY-MM-DD");
    }
    // 当前年份显示格式
    return dateToCheck.format("MM-DD");
};

/**
 * 计算订阅剩余时间
 * @param timestamp
 */
export const calculateSubscriptionTime = (timestamp: number) => {
    if (!timestamp) return null;
    // 订阅结束时间
    const currentPeriodEndDate = dayjs.unix(timestamp).format("YYYY-MM-DD");
    // 相差时间

    const diffDate = dayjs(currentPeriodEndDate).diff(dayjs(), "day");

    return diffDate <= 0 ? "0" : diffDate.toString();
};

/**
 * tab id index
 * @param index
 */
export const a11yProps = (index: number) => {
    return {
        id: `doc-tab-${index}`,
        "aria-controls": `doc-tab-panel-${index}`,
    };
};
