import {styled} from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import React from "react";

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
    variant?: "fullWidth" | "standard" | "scrollable" | undefined
}

export const MTabs = styled((props: StyledTabsProps) => (
    <Tabs
        scrollButtons={false}
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    minHeight: 28,
    "& .MuiTabs-indicator": {
        height: "4px",
        display: "none",
        justifyContent: "center",
        backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
        maxWidth: 80,
        width: "100%",
        height: "4px",
        borderRadius: 2,
        backgroundColor: "#635ee7",
        background: "linear-gradient(270deg, #68CCD8, #60A8FF)",
    },
    '& .MuiTabs-flexContainer': {
        gap: '4px'
    }
});
