import React, {useMemo, useState} from "react";
import {
    useGetIdentity,
    useIsAuthenticated,
    useLogout,
    useMenu,
    useTranslate,
} from "@refinedev/core";
import {useLocation, useNavigate} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import {MeRoot} from "../../lib/api/me/type";
import {useAuthDialog} from "../../contexts/auth-dialog";
import {MMenu} from "../customizedMUI/customizedMenu";
import {MMenuItem} from "../customizedMUI/customizedMenuItem";
import {AuthType} from "../../types";
import {useSubscribeRouter} from "../../hooks/useSubscribeRouter";
import {useSubscribe} from "../../hooks/useSubscribe";
import signInIcon from "/menu/signin_icon@2x.png";
import homeMore from "/menu/home_more@2x.png";
import menuUser from "/menu/menu_user@2x.png";
import vipIcon from "/plan/vip_icon@2x.png";

type MenuListList = {
    label: string | undefined;
    path?: string;
    value?: AuthType | string;
    authenticated?: boolean | undefined;
    divider?: boolean | undefined;
};

export const User = () => {
    const {data: identity,refetch} = useGetIdentity<MeRoot>();
    const translate = useTranslate();
    const {data} = useIsAuthenticated();
    const {selectedKey} = useMenu();
    const navigate = useNavigate();
    const {subscribeRouter} = useSubscribeRouter()
    const location = useLocation();
    // 是否是首页
    const isHome = location.pathname === "/";
    const {openAuthDialog} = useAuthDialog();
    // dialog 状态
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
    const {mutate: mutateLogout} = useLogout();
    // 多语言切换
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const menuOpen = Boolean(anchorEl);

    // 关闭logout dialog
    const handleCloseLogoutDialog = () => {
        setOpenLogoutDialog(false);
    };

    // 退出登录
    const handleLogout = async () => {
        await refetch()
        mutateLogout({redirectPath: "/"});
        handleCloseLogoutDialog();
    };
    // 打开logout dialog
    const handleOpenLogoutDialog = () => {
        setOpenLogoutDialog(true);
    };
    // 打开menu
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    // 关闭
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    // 点击登录
    const handleLogin = () => {
        handleMenuClose();
        openAuthDialog("LOGIN");
    };
    // 跳转路由或open dialog
    const handleClickItem = (path?: string, value?: AuthType | string) => {
        handleMenuClose();
        // 路由跳转
        if (path) {
            navigate(path);
            // 未登录 弹窗登录
            if (!data?.authenticated && path === "/subscription") {
                openAuthDialog("LOGIN");
            }
        }
        // dialog
        if (value) {
            switch (value) {
                case "LOGOUT":
                    return handleOpenLogoutDialog();
                default:
                    return openAuthDialog(value as AuthType);
            }
        }
    };

    // 点击跳转
    const handleVipIcon = () => {
        subscribeRouter()
    };
    // 是否订阅
    const {isSubscribe} =useSubscribe()
    // menu
    const menuList: MenuListList[] = useMemo(() => [
        {
            label: translate("header.subscribed", "My subscribed"),
            path: "/subscription",
            divider:true
        },
        {
            label: translate("header.calendar", "Earnings Calendar"),
            path: "/calendar",
            divider:true
        },
        {
            label: translate("header.deepTranslation", "Deep Translation"),
            path: "/deep-translation",
            divider:true
        },
        {
            label: translate("header.plan", "Plan"),
            path: "/pricing",
            divider:true
        },
        {
            label: translate("header.feedback", "Feedback"),
            path: "/feedback",
            divider: !!data?.authenticated
        },
        {
            label: translate("logout.title", "Logout"),
            value: "LOGOUT",
            authenticated: !data?.authenticated,
            divider: !data?.authenticated
        },
    ], [data])
    return (
        <div className="flex justify-center items-center gap-x-4 font-medium">
            <div className="flex justify-center items-center gap-x-4">
                {isSubscribe && (
                    <button
                        onClick={handleVipIcon}
                        className={`${isHome ? "block" : "hidden"} md:block`}
                    >
                        <img src={vipIcon} alt="vipIcon" className="h-5 md:h-6"/>
                    </button>
                )}
                <button
                    className="h-8 flex justify-between items-center rounded-full gap-2"
                    onClick={handleMenuClick}
                    id="menu-button"
                    aria-controls={menuOpen ? "menu-list" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                >
                    <img
                        src={signInIcon}
                        alt="signInIcon"
                        className="w-8 h-8 hidden md:block"
                    />
                    <img src={homeMore} alt="homeMore" className="w-[16px] md:hidden"/>
                </button>
            </div>
            <MMenu
                id="menu-list"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "menu-button",
                }}
                transformOrigin={{horizontal: "right", vertical: "top"}}
                anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                className="divide-y"
            >
                {data?.authenticated ? (
                    <MMenuItem divider>
                        <div>
                            <h5>{translate("header.user", "User")}</h5>
                            <p className="text-xs">{identity?.email}</p>
                        </div>
                    </MMenuItem>
                ) : (
                    <MMenuItem onClick={handleLogin} divider>
                        <div className="flex gap-x-2 text-[var(--primary)]">
                            <img
                                src={menuUser}
                                alt="menuUser"
                                className="w-[24px] h-[24px]"
                            />
                            <span>{translate("header.login", "Log in")}</span>
                        </div>
                    </MMenuItem>
                )}

                {menuList.map(({path, label, authenticated, value, divider}, index) =>
                    authenticated === false || authenticated === undefined ? (
                        <MMenuItem
                            key={index}
                            divider={divider}
                            selected={path === selectedKey}
                            onClick={() => handleClickItem(path, value)}
                        >
                            {label}
                        </MMenuItem>
                    ) : null
                )}
            </MMenu>
            <Dialog onClose={handleCloseLogoutDialog} open={openLogoutDialog}>
                <div className="p-6">
                    <p className="text-center">
                        {translate("logout.confirmLogout", "Confirm logout?")}
                    </p>
                    <div className="pt-16 pb-2 flex justify-center items-center gap-x-6">
                        <button
                            onClick={handleCloseLogoutDialog}
                            className="search-button w-28 h-8 bg-[var(--primary-394FF6)] flex justify-center items-center gap-x-2 text-white md:text-sm rounded-full"
                        >
                            {translate("logout.cancel", "cancel")}
                        </button>
                        <button
                            onClick={handleLogout}
                            className="w-28 h-8 flex justify-center items-center gap-x-2 border md:text-sm rounded-full disabled:cursor-not-allowed"
                        >
                            {translate("logout.logout", "Logout")}
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
