import {styled} from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import React from "react";

interface StyledTabProps {
    label: string;
}

const tabStyles = {
    mx: 0,
    p: 0,
    px: 2,
    py: 0.8,
    minWidth: 0,
    minHeight: 28,
    borderRadius: '6px',
    fontSize: {xs: "14px", lg: "14px"},
    color: "#000000",
    textTransform: "none",
    fontWeight: "400",
};

export const MTab = styled((props: StyledTabProps) => (
    <Tab disableRipple sx={tabStyles} {...props} />
))(({theme}) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    color: "var(--primary-2B363E)",
    backgroundColor: "var(--primary-F3F4F6)",
    "&.Mui-selected": {
        color: "var(--primary-F4F4F5)",
        backgroundColor: "var(--primary)",
        fontWeight: "500",
    },
    "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
}));
