// 语言列表
export const LOCALE_LIST = [
  {
    value: "en",
    label: "English",
  },
  // {
  //   value: "es",
  //   label: "Spanish",
  // },
  {
    value: "zh",
    label: "简体中文",
  },
];

export const DEEP_TRANSLATION = [
  {
    value: "zh",
    label: "中文",
  },
];

// 财报类型
export const COMPANY_SELECT_LIST = [
  {
    value: "all",
    label: "all",
  },
  {
    value: "10-Q,10-K,20-F",
    label: "financialReport",
  },
  {
    value: "6-K,8-K",
    label: "importantDisclosure",
  },
];

// 邮箱
export const MAIL = "alex@oblazo.com";

// 常见问题
export const SUGGESTED_QUESTION_LIST = [
  "What is the company's business model?",
  "What is the primary source of income for the company?",
  "How is the revenue composition and gross profit margin of the company's business segments?",
  "View revenue analysis",
];

// select控制高度
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

// 日期格式
export const DATE_FORMAT = "YYYY-MM-DD";

// vip金额
export const PLAN_AMOUNT = 199;

// 深度翻译tab
export const DEEP_TRANSLATION_TABS=[
    {
        _index:0,
        label:'电话会议',
        localeName:"earningsCall"
    },
    {
        _index:1,
        label:'财报',
        localeName:"frd"
    }
]
