import React, { memo } from "react";
import classNames from "classnames";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./../../styles/markdown.css";

type MarkdownContentProps = {
  content: string;
  className?: string | null | undefined;
};

export const MarkdownContent: React.FC<MarkdownContentProps> = memo((props) => {
  const { content, className } = props;
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      children={content}
      className={classNames(
        className ? className : "prose-sm md:prose overflow-auto",
        "prose-headings:font-medium md:max-w-none markdown text-[var(--primary-2B363E)]"
      )}
    />
  );
});
